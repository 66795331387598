import React from "react";
import { Redirect } from 'react-router-dom';
import { Button } from "reactstrap";


const PlanChoiceContainer = (props) => {
    console.log('props: ' + JSON.stringify(props))
    return(
        <div className="background">
            <div className="container">
                {props.loggedIn ? <Redirect to="/"/>: <div/> }
                {
                    props.isRegistered
                    ?
                    <div>
                        <div className="spacer" />
                        <h1>Upgrade to HeadsUp Pro</h1>
                        <p>Need to track more than 3 contacts or ideas? Upgrade to pro and get 100.</p>
                        <p>$4.99/month. Cancel anytime. 60-day money-back guarantee.</p>
                        <form onSubmit={props.submitBuyPlan}>
                            <Button className="wider" type="submit">Upgrade</Button>
                        </form>
                    </div>
                    :
                    <div>
                        <div className="spacer" />
                        <h1>Don't have an account yet?</h1>
                        <a className="login-register" href="/login"><Button>Login</Button></a>
                        <a className="login-register" href="/register"><Button>Register</Button></a>
                    </div>
                }
                {/* When SaaS-ready, replace "Registration" with a buy/sign-up page */}
                <div className="spacer"/>
            </div>
        </div>
    )
}

export default PlanChoiceContainer;