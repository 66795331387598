import React from "react";
import './App.css';

const AboutPage = () => {

    return (
        <div className="background">
            <br/>
            <br/>
            <br/>
            <br/>
            <h1>Page not found</h1>
            <br/>
            <div className="explainer container">
                <br/>
                <p><strong>Whoops. </strong>That ain't right. <br/>That page doesn't exist. <a className="blank-link" href="/">Head back to the home page.</a></p>
            </div>
        </div>
    )
}

export default AboutPage;