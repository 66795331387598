import React from "react";

const HomePage = () => {
    return (
        <div className="background">
            <div className="spacer"></div>
            <h1 className="extra-padding">Never forget to follow up again.</h1>
            <div className="explainer">
                <p className="extra-padding"><strong>HeadsUp</strong> lets you set timed reminders for your new connections and important to-dos.</p>
                <br/>
                <div className="cta"><a href="/register">Get started</a></div> 
            </div>
            <div className="spacer"></div>
            <section>
                <div className="mini-spacer"></div>
                <div className="explainer">
                    <p>You meet someone at the coffee shop, job fair, or happy hour. You take their card and maybe add them on social media.</p>
                    <br/>
                    <p>Nice job. <b>But did you forget to follow up?</b> Three months from now, are you comfortable reaching out again or is the contact cold?</p>
                    <br/>
                    <p><strong>Never forget again.</strong> Create automated reminders for every contact you meet, and maximize your network's potential.</p>
                </div>
                <div className="spacer"></div>
            </section>
            <div className="separator"></div>
            <section>
                <div className=" mid">
                <div className="spacer"></div>
                <h2 className="header">How it works</h2>
                    <div className="mini-spacer"></div>
                    <div className="mini-spacer"></div>
                    <div className="half-spacer"></div>
                    <div className="row step-container">
                        <div className="col-md value-prop steps">
                            <div className="mini-spacer"></div>
                            <h4>1. Sign up (for free)</h4>
                            <p>No credit card needed.</p>
                        </div>
                        <div className="thin-tall-line"></div>
                        <br/>
                        <div className="col-md value-prop steps">
                            <div className="mini-spacer"></div>
                            <h4>2. Create a contact</h4>
                            <p>And say when you want your reminder.</p>
                        </div>
                        <div className="thin-tall-line"></div>
                        <br/>
                        <div className="col-md value-prop steps">
                            <div className="mini-spacer"></div>
                            <h4>3. Get an email reminder</h4>
                            <p>For each specific contact on the day you set.</p>
                        </div>
                    </div>
                    <div className="spacer"></div>
                </div>
            </section>
            <div className="separator"></div>
            <div>
                <div className="spacer"></div>
                <div className="explainer">
                    <div className="cta"><a href="/register">Sign up</a></div>
                    <p>Get started—totally free.</p>
                </div>
                <div className="spacer"></div>
            </div>
            <h6 className="small">Created by Deeter Cesler | Copyright © 2020</h6>
            <div className="mini-spacer"></div>
        </div>
    )
}

export default HomePage;