import React from "react";

const CancelPage = (props) => {
    console.log('props:')
    console.log(props)
    return(
        <div className="background">
            <div className="container">
                <div className="mini-spacer"/>
                <div className="mini-spacer"/>
                {
                    props.confirmCancel
                    ?
                    <div>
                        <h5>You've successfully cancelled your subscription. Please allow 24 hours for this to fully update.</h5>
                        <p>If you're charged during this period, <a href="mailto:deeter.cesler@gmail.com">let me know</a> and I'll fix that for you.</p>
                    </div>
                    :
                    <div>
                        <h1>Are you sure you want to cancel your account?</h1>
                        <br/>
                        <div className="container">
                            <div className="row text-left">
                                <div className="col-md" />
                                <div className="col-md light-gray">
                                    <span>
                                        your email: 
                                        <br/>
                                        <strong>{props.email}</strong>
                                        <br/>
                                        <br/>
                                        your account type: 
                                        <br/>
                                        <strong>{props.loggedIn ? "Pro Account" : "Free-tier Account"}</strong>
                                        <div>
                                            <form onSubmit={props.cancelSubscription}>
                                                <button id="shitty-button" type="submit" className="link">Yes, cancel my account.</button>
                                            </form>
                                        </div>
                                    </span>
                                </div>
                                <div className="col-md" />
                            </div>
                            <br/>
                            <div className="row">
                                { props.owner ? <a className="col" href="/owner">Go to owner page</a> : <div/> }
                            </div>
                        </div>
                    </div>
                }
                <br/>
                <footer>
                    If you've got a second, <a href="mailto:deeter.cesler@gmail.com">I'd love to hear why you're cancelling.</a> 
                    <br/>
                    I'm always looking to keep the good things and fix what's broken.
                </footer>
                <div className="mini-spacer" />
                <div className="mini-spacer" />
            </div>
        </div>
    )
}

export default CancelPage;