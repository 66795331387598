import React from "react";
import { Redirect } from 'react-router-dom';
import { Button } from "reactstrap";
import Registration from "../Registration";


const PlanChoiceContainer = (props) => {
    console.log('props: ' + JSON.stringify(props))
    return(
        <div className="background">
            <div className="container">
                {props.loggedIn ? <Redirect to="/"/>: <div/> }
                <div className="mini-spacer"/>
                <div className="mini-spacer"/>
                <h1>Get started in under 30 seconds.</h1>
                <div className="mini-spacer"/>
                <div className="mini-spacer"/>
                <div className="row">
                    <div className="col-md steps">
                        <h3>1. Register an Account</h3>
                        <p>Make a free account today. No credit card required. Sign up below.</p>
                    </div>
                    <br/>
                    <div className="col-md steps">
                        <h3>2. Start Creating Contacts</h3>
                        <p>You can set reminders for up to 3 contacts on a free account.</p>
                    </div>
                    <br/>
                    <div className="col-md steps">
                        <h3>3. Upgrade to Pro.</h3>
                        <p>Run out of contacts? <a href="/upgrade">Upgrade to pro</a> to set reminders for up to 100 contacts.</p>
                    </div>
                </div>
                <div className="mini-spacer"/>
                <div className="row">
                    <div className="col-md">
                        <Registration submitRegistration={props.submitRegistration} handleInputs={props.handleInputs} submitLogin={props.submitLogin} loggedIn={props.loggedIn}/>
                    </div>
                </div>
                
                <br/>
                {
                    props.planType 
                    &&
                    <div>
                        {
                            props.isRegistered
                            ?
                            <form onSubmit={props.submitBuyPlan}>
                                <Button type="submit">Submit</Button>
                            </form>
                            :
                            <div>
                                <a href="/register"><Button>Register an account</Button></a>
                            </div>
                        }
                    </div>
                }
                {/* When SaaS-ready, replace "Registration" with a buy/sign-up page */}
                <div className="spacer"/>
            </div>
        </div>
    )
}

export default PlanChoiceContainer;