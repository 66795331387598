import React, { Component } from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import HomePage from './HomePage';
import AboutPage from './AboutPage';
import NewContact from './NewContact/index';
import AllContactsContainer from './AllContactsContainer';
import NavBar from './NavBar';
import LoginRegisterContainer from './LoginRegisterContainer';
import LogoutPage from './LogoutPage';
import HelpPage from './HelpPage';
import ResetPasswordAttempt from './ResetPasswordAttempt';
import ResetPassword from './ResetPassword';
import { loadStripe } from '@stripe/stripe-js';
import PlanChoiceContainer from './PlanChoiceContainer';
import UpgradePage from './UpgradePage';
import SuccessPage from './SuccessPage';
import AccountPage from './AccountPage';
import CancelPage from './CancelPage';
import NotFoundPage from './NotFoundPage';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-110417068-7');
ReactGA.pageview(window.location.pathname + window.location.search);


// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const backendURL = process.env.REACT_APP_BACKEND_SERVER_ADDRESS || "https://followup-v1.herokuapp.com/";

class App extends Component {
  constructor(){
    super();
    this.state = {
      loggedIn: "",
      email: null,
      contact: null,
      isRegistered: null,
      isPro: null,
      sessionId: null,
      planType: null,
      confirmCancel: null,
    }
  }


  checkForCookie = async () => {
    // console.log("getting token: ", localStorage.getItem("token"))
    if(localStorage.getItem("token") !== "null"){
      try{
        const targetUrl = backendURL + "auth/verify";
        const getUser = await fetch(targetUrl, {
          method: 'POST',
          // body: localStorage.token,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': "POST",
            'credentials': 'same-origin',
            "Authorization": localStorage.getItem("token")
          } 
        });
        const parsedResponse = await getUser.json();
        if(parsedResponse.status === 200){
          await this.setState({
            ...this.state,
            loggedIn: parsedResponse.loggedIn,
            isRegistered: parsedResponse.isRegistered,
            email: parsedResponse.email,
            id: parsedResponse.id,
            name: parsedResponse.name,
            owner: parsedResponse.owner,
            isLoaded: true,
            isPro: parsedResponse.isPro,
            signupDate: parsedResponse.signupDate,
          })
          if(localStorage.getItem("loggedIn") !== "true"){
            localStorage.setItem("loggedIn", true);
          }
        } else if (parsedResponse.status === 500){
          console.log("INTERNAL SERVER ERROR")
        } else if (parsedResponse.status === 404){
          console.log("NO USER FOUND")
          localStorage.setItem("loggedIn", false);
        } else {
          this.setState({
            loggedIn: false,
            email: ""
          })
        }
      } catch(err){
        console.log("failed to get cookie", err);
        localStorage.setItem("loggedIn", false);
        localStorage.setItem("email", null);
        localStorage.setItem("token", null);
        this.setState({
          loggedIn: false
        })
      }
    } else{
      console.log("else statement hit");
    }
    return
  }


  componentDidMount(){
    this.checkForCookie();
  }

  handleInputs = (e) => {
    this.setState({
      ...this.state,
      [e.currentTarget.name]: e.currentTarget.value
    })
  }

  fetchCheckoutSession = async () => {
    try {
      console.log('before you send: plan type')
      console.log(this.state.planType)
      const targetUrl = backendURL + 'auth/checkout'
      const shite = await fetch(targetUrl, {
        method: 'POST',
        body: JSON.stringify(this.state),
        headers: {
          'Access-Control-Allow-Origin': targetUrl,
          'Content-Type': 'application/json',
          'credentials': 'same-origin',
        }
      });
      const parsed = shite.json();
      return parsed;
    } catch(err){
      console.log('err: ' + err);
    }
  }

  submitBuyPlan = async (e) => {
    e.preventDefault();
    this.setState({
      planType: 'proPlan'
    })
    try {
      const session = await this.fetchCheckoutSession();
      const sessionId = session.sessionId;
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });
      if(error) {
        alert('error?? ' + error);
        throw new Error(error);
      }
    } catch (err) {
      console.log('err! ' + err)
    }
  }

  submitRegistration = async (e) => {
    e.preventDefault();
    try{
      const targetUrl = backendURL + 'auth/register'
      const createUser = await fetch(targetUrl, {
        method: 'POST',
        body: JSON.stringify(this.state),
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': "POST"
        } 
      });
      const parsedResponse = await createUser.json();
      if(parsedResponse.status === 200){
        this.setState({
          loggedIn: true,
          email: parsedResponse.data.email,
          id: parsedResponse.data._id
        })
      } else if (parsedResponse.status === 500){
        console.log("INTERNAL SERVER ERROR")
      } else if (parsedResponse.status === 401){
        console.log("Email already in use.")
      }
    }catch(err){
      console.log(err, " error")
    }
  }

  submitLogin = async (e) => {
    e.preventDefault();
    console.log(this.state)
    let parsedLogged;
    try{
      console.log("submitting login");
      const targetUrl = backendURL;
      const loggedUser = await fetch(targetUrl + 'auth/login', {
        method: 'POST',
        body: JSON.stringify(this.state),
        headers: {
          'Access-Control-Allow-Origin': targetUrl,
          'Content-Type': 'application/json',
          'credentials': 'same-origin',
        } 
      });
      parsedLogged = await loggedUser.json();
      // res => setToken(res.token);
      if(parsedLogged.status === 200){
        this.setState({
          isRegistered: true,
          loggedIn: parsedLogged.loggedIn,
          email: parsedLogged.data.email,
          id: parsedLogged.data._id
        });
        localStorage.setItem("token", parsedLogged.token);
        localStorage.setItem("email", parsedLogged.data.email);
        localStorage.setItem("id", parsedLogged.data._id);
      } 
      else if (parsedLogged.status === 500){
        console.log("INTERNAL SERVER ERROR")
      } else {
        console.log("parsed log and shiiiiit:", parsedLogged);
        alert("LOGIN FAILED.")
      }
    }catch(err){
      // console.log("parsed: ", parsedLogged);
      console.log(parsedLogged);
      console.log("Error, boi: ", err)
      alert("LOGIN FAILED WITH ERROR: " + err)
    }
  }

  logout = () => {
    console.log("LOGGING OUT")
    localStorage.setItem("loggedIn", false)
    localStorage.setItem("email", null)
    localStorage.setItem("id", null)
    localStorage.setItem("token", null)
    this.setState({
      loggedIn: false,
      email: "",
      password: ""
    })
  }

  setToken = (token) => {
    localStorage.setItem("token", token);
  }

  homepage = () => {
    return <HomePage />
  }
  
  aboutPage = () => {
    return <AboutPage/>
  }

  newContact = () => {
    return this.state.email != null
    ? <NewContact loggedIn={this.state.loggedIn} isRegistered={this.state.isRegistered} email={this.state.email} />
    : 
    <div className="black-floater">
        <div className="spacer">.</div>
        <p>
          You need to log in to view your contacts.
        </p>
      </div>
  }
  
  allContacts = () => {
    return this.state.isRegistered && <AllContactsContainer loggedIn={this.state.loggedIn} isRegistered={this.state.isRegistered} email={this.state.email}/>
  }

  loginRegisterPage = () => {
    return <LoginRegisterContainer loggedIn={this.state.loggedIn} submitLogin={this.submitLogin} handleInputs={this.handleInputs} submitRegistration={this.submitRegistration} isRegistered={this.state.isRegistered} />
  }
  
  logoutPage = () => {
    return <LogoutPage logout={this.logout}/>
  }
  
  helpPage = () => {
    return <HelpPage/>
  }

  resetPasswordAttempt = () => {
    return <ResetPasswordAttempt send={this.state.send} submitReset={this.submitReset} handleInputs={this.handleInputs} />
  }

  resetPassword = (props) => {
    return <ResetPassword {...props} setNewPassword={this.setNewPassword} handleInputs={this.handleInputs} password={this.state.password} passwordCopy={this.state.passwordCopy} send={this.state.send} />
  }

  submitReset = async (e) => {
    e.preventDefault();
    let parsedLogged;
    try{
      console.log("Submitting reset.");
      const targetUrl = backendURL  + 'auth/reset';
      const loggedUser = await fetch(targetUrl, {
        method: 'POST',
        body: JSON.stringify(this.state),
        headers: {
          // 'Access-Control-Allow-Origin': targetUrl,
          'Content-Type': 'application/json',
          'credentials': 'same-origin',
          'Access-Control-Allow-Origin': '*',
        } 
      });
      parsedLogged = await loggedUser.json();
      if(parsedLogged.status === 200){
        this.setState({
          ...this.state,
          send: true,
        });
      } 
      else if (parsedLogged.status === 500){
        console.log("INTERNAL SERVER ERROR")
      } else {
        alert("LOGIN FAILED. RESPONSE: ", JSON.stringify(parsedLogged));
      }
    }catch(err){
      console.log(parsedLogged);
    }
  }

  setNewPassword = async (e) => {
    e.preventDefault();
    try{
      console.log("resetting password fr");
      const targetUrl = backendURL  + 'auth/reset/confirm';
      const response = await fetch(targetUrl, {
        method: 'POST',
        body: JSON.stringify({
          id: window.location.pathname.slice(15),
          password: this.state.password
        }),
        headers: {
          'Access-Control-Allow-Origin': targetUrl,
          'Content-Type': 'application/json',
          'credentials': 'same-origin',
        } 
      });
      const parsedResponse = await response.json();
      if(parsedResponse.status === 200){
        this.setState({
          ...this.state,
          send: true,
        });
      } 
    } catch (err) {
      console.log('ERR: ' + err)
    }
  }

  cancelSubscription = async (e) => {
    // is not in use yet
    e.preventDefault();
    try{
      console.log("resetting password fr");
      const targetUrl = backendURL  + 'auth/cancel';
      console.log('this.state')
      console.log(this.state)
      const response = await fetch(targetUrl, {
        method: 'POST',
        body: JSON.stringify(this.state),
        headers: {
          'Access-Control-Allow-Origin': targetUrl,
          'Content-Type': 'application/json',
          'credentials': 'same-origin',
        } 
      });
      const parsedResponse = await response.json();
      if(parsedResponse.status === 200){
        this.setState({
          ...this.state,
          send: true,
        });
      } 
    } catch (err) {
      console.log('ERR: ' + err)
    }
  }

  signupPage = () => {
    return <PlanChoiceContainer isRegistered={this.state.isRegistered} handleCheck={this.handleCheck} loggedIn={this.state.loggedIn} submitRegistration={this.submitRegistration} handleInputs={this.handleInputs} />
  }

  upgradePage = () => {
    return <UpgradePage isRegistered={this.state.isRegistered} submitBuyPlan={this.submitBuyPlan} loggedIn={this.state.loggedIn} isPro={this.state.isPro}/>
  }

  successPage = (props) => {
    return <SuccessPage {...props} confirmSessionId={this.confirmSessionId} loggedIn={this.state.loggedIn} submitLogin={this.submitLogin} handleInputs={this.handleInputs} handleCheck={this.handleCheck} submitRegistration={this.submitRegistration} message={this.state.message} planType={this.state.planType} password={this.state.password} passwordCopy={this.state.passwordCopy} />
  }

  accountPage = () => {
    return <AccountPage email={this.state.email} name={this.state.name} owner={this.state.owner} planType={this.state.planType} loggedIn={this.state.loggedIn} isPro={this.state.isPro} />
  }

  cancelPage = () => {
    return <CancelPage email={this.state.email} name={this.state.name} owner={this.state.owner} planType={this.state.planType} loggedIn={this.state.loggedIn} isPro={this.state.isPro} cancelSubscription={this.cancelSubscription} confirmCancel={this.state.confirmCancel} />
  }

  notFoundPage = () => {
    return <NotFoundPage />
  }

  render(){
    return (
        <div className="App">
          <div className="separator"></div>
          <NavBar loggedIn={this.state.loggedIn} isRegistered={this.state.isRegistered} />
          <Switch>
            <Route exact path="/" render={this.homepage}/>
            <Route exact path="/about" render={this.aboutPage}/>
            <Route exact path="/login" render={this.loginRegisterPage}/>
            <Route exact path="/logout" render={this.logoutPage}/>
            <Route exact path="/register" render={this.signupPage}/>
            <Route exact path="/contacts/new" render={this.newContact}/>
            <Route exact path="/contacts/all" render={this.allContacts}/>
            <Route exact path="/help" render={this.helpPage}/>
            <Route exact path="/reset" render={this.resetPasswordAttempt}/>
            <Route exact path="/reset/confirm/:id" render={(props) => this.resetPassword(props)}/>
            <Route exact path="/upgrade" render={this.upgradePage}/>
            <Route exact path="/success/:sessionId" render={this.successPage}/>
            <Route exact path="/account" render={this.accountPage}/>
            <Route exact path="/cancel" render={this.cancelPage}/>
            <Route path="*" render={this.notFoundPage}/>
          </Switch>
        </div>
    );
  }
}

export default App;