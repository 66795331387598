import React from "react";
import { Form, Input, Button, Label, FormGroup } from "reactstrap";
import { Redirect } from "react-router-dom";

const backendURL = process.env.REACT_APP_BACKEND_SERVER_ADDRESS || "https://followup-v1.herokuapp.com/";

export default class NewContact extends React.Component{
    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            contactName: "",
            contactSummary: "",
            dropdownOpen: false,
            repeatingReminderRhythm: "3",
            info: null,
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.currentTarget.name]: e.currentTarget.value
        });
        console.log('state')
        console.log(this.state)
    }

    postNewContact = async (e) => {
        e.preventDefault();
        try{
            console.log("POSTING")
            const pull = await fetch(backendURL + "contact/new", {
              method: "POST",
              body: JSON.stringify({
                contactName: this.state.contactName,
                contactSummary: this.state.contactSummary,
                repeatingReminderRhythm: this.state.repeatingReminderRhythm,
                repeatingReminder: this.state.repeatingReminder,
              }),
              headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("token")
              } 
            });
            const parsedPull = await pull.json();
            console.log("information got", parsedPull)
            this.setState({
                redirect: true
            })
            return parsedPull
        } catch(err){
            console.log("error:", err);
        }
    };

    deleteContact = async () => { // NOT FINSIHED
        try{
            console.log("attempting DELETE");
            await fetch(backendURL + "contact/delete", {
                method: "POST",
            })
        }catch(err){
            console.log("error: ", err);
        }
    }

    toggle() {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
    }

    getAllContacts = async () => {
        try{
            const pull = await fetch(backendURL + "contact/" + this.props.email,{
                headers: {
                    "authorization": localStorage.getItem("token")
                }
            });
            const parsedPull = await pull.json();
            let names = parsedPull.data.filter(item => item.contactName);
            this.setState({ // need to refactor this out since this component is now functional
                info: parsedPull.data,
                loaded: true,
                completeData: names
            });
            return parsedPull
        } catch(err){
            console.log("whoops lol")
            console.log("error:", err);
        }
    }

    componentDidMount() {
        this.getAllContacts();
    }

    render(){
        const {redirect} = this.state;
        if(redirect){
            return <Redirect to="/contacts/all"/> 
        }
        const hasBasicRoom = !this.props.isRegistered && this.state.info && this.state.info.length < 3;
        const hasProRoom = this.props.isRegistered && this.state.info && this.state.info.length < 100;
        return(
            <div className="background">
                <div className="container">
                    <div className="spacer"></div>
                    {this.state.loaded ? 
                    <div>
                        {
                        hasBasicRoom || hasProRoom ?
                        <div>
                            <h3>add a new reminder</h3>
                            <Form className="explainer" onSubmit={this.postNewContact}>
                                <Input onChange={this.handleChange} type="text" name="contactName" placeholder="Topic or name..."/>
                                <Input onChange={this.handleChange} type="textarea" name="contactSummary" placeholder="What's this reminder about?"/>
                                <div className="mini-spacer"/>
                                <div className="container text-center">
                                    <Label className="explainer-text">When do you want a reminder?</Label>
                                    <div className="row new-contact-row">
                                        <div className="input-item">
                                            <FormGroup>
                                                <Input onChange={this.handleChange} type="number" name="repeatingReminderRhythm" placeholder="3" />
                                            </FormGroup>
                                        </div>
                                        <div className="input-item">
                                            <FormGroup>
                                                <Input onChange={this.handleChange} type="select" name="repeatingReminder">
                                                    <option value="1" defaultValue="selected">Day(s)</option>
                                                    <option value="7">Week(s)</option>
                                                    <option value="30">Month(s)</option>
                                                </Input>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                                <Button type="submit">Submit</Button>
                            </Form>
                            <div className="spacer"/>
                        </div>
                        :
                        <div>
                            <h2>You've already created the max number of contacts allowed.</h2>
                            <p>Either <a href="/contacts/all">choose a contact to delete</a>, or <a href="/upgrade">upgrade to Pro</a>.</p>
                        </div>
                        }
                    </div> : <h3>Loading...</h3>}
                </div>
            </div>
        )
    }
}