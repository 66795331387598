import React from "react";

const AccountPage = (props) => {
    console.log('props:')
    console.log(props)
    return(
        <div className="background">
            <div className="container">
                <div className="mini-spacer"/>
                <div className="mini-spacer"/>
                <h1>Account</h1>
                <br/>
                <div className="container">
                    <div className="row text-left">
                        <div className="col-md" />
                        <div className="col-md light-gray bubble">
                            <span>
                                email:
                                <br/> 
                                <strong>{props.email}</strong>
                            </span>
                            <br/> 
                            <br/> 
                            <span>
                                name:
                                <br/> 
                                <strong>{props.name}</strong>
                            </span>
                            <br/> 
                            <br/> 
                            <span>
                                account type:
                                <br/> 
                                <strong>{props.loggedIn ? "Pro Account" : "Free-tier Account"}</strong>
                            </span>
                            <br/> 
                            <br/> 
                            <span className="forgot">
                                <a href="/reset">Reset password?</a>
                            </span>
                            <span>{!props.loggedIn ? 
                                <div>
                                    <a className="link" href="/upgrade" >Upgrade to Pro</a>
                                </div>
                                :
                                <div>
                                    <a className="link" href="mailto:deeter.cesler@gmail.com?subject=Notice of cancellation&body=Hi, I'd like to cancel my subscription to HeadsUp. My email is _______">Cancel account</a>
                                </div>
                                }
                            </span>
                        </div>
                        <div className="col-md" />
                    </div>
                    <br/>
                    <div className="row">
                        { props.owner ? <a className="col" href="/owner">Go to owner page</a> : <div/> }
                    </div>
                </div>
                <br/>
                <footer>
                    Love HeadsUp? Hate it? <a href="mailto:deeter.cesler@gmail.com">Let me know.</a> 
                    <br/>
                    I'm always looking to keep the good things and fix what's broken.
                </footer>
                <div className="mini-spacer" />
                <div className="mini-spacer" />
            </div>
            <h6 className="small">Created by Deeter Cesler | Copyright © 2020</h6>
            <div className="mini-spacer"></div>
        </div>
    )
}

export default AccountPage;