import React, {Component} from "react";
import AllContacts from "./AllContacts";
import { Redirect } from "react-router-dom";

const backendURL = process.env.REACT_APP_BACKEND_SERVER_ADDRESS || "https://followup-v1.herokuapp.com/";

class AllContactsContainer extends Component {
    constructor(props){
        super();
        this.state = {
            loaded: false,
        }
    }
    
    getAllContacts = async () => {
        console.log("tryna pull contacts")
        try{
            const pull = await fetch(backendURL + "contact/" + this.props.email,{
                headers: {
                    "authorization": localStorage.getItem("token")
                }
            });
            const parsedPull = await pull.json();
            let names = parsedPull.data.filter(item => item.contactName);
            this.setState({ // need to refactor this out since this component is now functional
                info: parsedPull.data,
                loaded: true,
                completeData: names
            });
            return parsedPull
        } catch(err){
            console.log("whoops lol")
            console.log("error:", err);
        }
    }

    componentDidMount = async () => {
        if(this.props.email !== undefined){
            this.getAllContacts();
        }
    }
    
    render(){
        const basicIsFull = this.props.isRegistered && !this.props.loggedIn && this.state.info && this.state.info.length >= 3;

        return (
            <div className="contacts-background">
                {this.state.data ? <Redirect to="/contacts/new"/>: <div/> }
                <div className="mini-spacer"/>
                <div className="container contacts-container large-view">
                    <div>
                        <div className="row">
                            <div className="col-lg"><strong>Name/Topic</strong></div>
                            <div className="col-sm"><strong>Description</strong></div>
                            <div className="col-lg"><strong>Edit/View</strong></div>
                            <div className="col-lg"><strong>Delete</strong></div>
                        </div>
                        {this.state.loaded? 
                        this.state.completeData.length > 0 ?
                            this.state.completeData.map((data)=>{
                                return <AllContacts data={data} key={data._id}/> 
                            })
                            :
                            <div>
                                <br/>
                                <div>There's nothing here yet!</div>    
                                <a href="/contacts/new"><strong>Add your first contact.</strong></a>
                            </div>
                        : 
                        <p>Loading...</p>
                        }
                    </div>
                </div>
                <div className="small-view">
                {basicIsFull && <div>
                    <h3>You have the max number of contacts allowed.</h3>
                    <p><a href="/upgrade">Upgrade to Pro.</a></p>
                </div>}
                {this.state.loaded?
                    this.state.completeData.length > 0 ?
                        this.state.completeData.map((data)=>{
                            return <AllContacts data={data} key={data._id}/> 
                        })
                        :
                        <div>
                            <br/>
                            <div>There's nothing here yet!</div>    
                            <a href="/contacts/new"><strong>Add your first contact.</strong></a>
                        </div>
                    :
                    <div>
                        <div>
                            <p>not loaded</p>
                        </div>
                    </div>
                    }
                </div>
                <div className="mini-spacer"></div>
                <h6 className="small">Created by Deeter Cesler | Copyright © 2020</h6>
                <div className="mini-spacer"></div>
            </div>
        )
    }
}

export default AllContactsContainer;