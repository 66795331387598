import React from "react";
import { Form, Button } from "reactstrap";
import EditContact from "../../EditContact";
import SingleContact from "../../SingleContact";

const backendURL = process.env.REACT_APP_BACKEND_SERVER_ADDRESS || "https://followup-v1.herokuapp.com/";

const AllContacts = (props) => {

    const deleteContact = async () => {
        try{
            console.log("DELETE");
            // console.log(this.props.data._id);
            const pull = await fetch(backendURL+"contact/" + props.data._id, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                "authorization": localStorage.getItem("token")
            } 
            });
            const parsedPull = await pull.json();
            return parsedPull
        } catch(err){
            console.log("error:", err);
        }
    }

    return(
        <div>
            <div className="large-view">
                <div className="row contacts-row">
                    <div className="col-lg">{props.data.contactName.length > 25 ? props.data.contactName.slice(0,25) + '...' : props.data.contactName}</div>
                    <div className="col-sm">{props.data.contactSummary.length > 25 ? props.data.contactSummary.slice(0, 25) + '...' : props.data.contactSummary}</div>
                    <div className="col-md">
                        <EditContact deleteContact={deleteContact} contact={props.data}/>
                    </div>
                    <div className="col-sm">
                        <Form onSubmit={deleteContact}>
                            <Button type="submit">
                            <svg height="25px" xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 50 50" enableBackground="new 0 0 50 50">
                                <path fill="#fff" d="M10.289,14.211h3.102l1.444,25.439c0.029,0.529,0.468,0.943,0.998,0.943h18.933  c0.53,0,0.969-0.415,0.998-0.944l1.421-25.438h3.104c0.553,0,1-0.448,1-1s-0.447-1-1-1h-3.741c-0.055,0-0.103,0.023-0.156,0.031  c-0.052-0.008-0.1-0.031-0.153-0.031h-5.246V9.594c0-0.552-0.447-1-1-1h-9.409c-0.553,0-1,0.448-1,1v2.617h-5.248  c-0.046,0-0.087,0.021-0.132,0.027c-0.046-0.007-0.087-0.027-0.135-0.027h-3.779c-0.553,0-1,0.448-1,1S9.736,14.211,10.289,14.211z   M21.584,10.594h7.409v1.617h-7.409V10.594z M35.182,14.211L33.82,38.594H16.778l-1.384-24.383H35.182z"/>
                                <path fill="#fff" d="M20.337,36.719c0.02,0,0.038,0,0.058-0.001c0.552-0.031,0.973-0.504,0.941-1.055l-1.052-18.535  c-0.031-0.552-0.517-0.967-1.055-0.942c-0.552,0.031-0.973,0.504-0.941,1.055l1.052,18.535  C19.37,36.308,19.811,36.719,20.337,36.719z"/>
                                <path fill="#fff" d="M30.147,36.718c0.02,0.001,0.038,0.001,0.058,0.001c0.526,0,0.967-0.411,0.997-0.943l1.052-18.535  c0.031-0.551-0.39-1.024-0.941-1.055c-0.543-0.023-1.023,0.39-1.055,0.942l-1.052,18.535C29.175,36.214,29.596,36.687,30.147,36.718  z"/>
                                <path fill="#fff" d="M25.289,36.719c0.553,0,1-0.448,1-1V17.184c0-0.552-0.447-1-1-1s-1,0.448-1,1v18.535  C24.289,36.271,24.736,36.719,25.289,36.719z"/>
                            </svg>
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="small-view">
                <SingleContact deleteContact={deleteContact} contact={props.data}/>
            </div>
        </div>
    )
}

export default AllContacts;