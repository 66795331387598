import React from "react";
import Login from "../Login";
import { Redirect } from 'react-router-dom';

const LoginRegisterContainer = (props) => {
    return(
        <div>
            {props.isRegistered ? <Redirect to="/contacts/all"/>: <div/> }
            <div className="background">
                {props.isRegistered ? <Redirect to="/"/>: <div/> }
                <div className="spacer"/>
                <Login submitRegistration={props.submitRegistration} handleInputs={props.handleInputs} submitLogin={props.submitLogin} loggedIn={props.loggedIn}/>
                <div className="mini-spacer"/>
                <div className="spacer"/>
            </div>
        </div>
    )
}

export default LoginRegisterContainer;